<div class="vl-form-group-s1">
	<label class="vl-form-group-s1__label" [for]="id" *ngIf="type !== 'check' && type !== 'check-invert' && label">{{ label }}</label>
	<div class="vl-form-group-s1__wrapper">
		<label class="vl-form-group-s1__wrapper__content" [for]="id">
			<!-- Dinamic -->
			<ng-container *ngIf="type === 'text' || type === 'number'">
				<input
					formInputTrim
					class="vl-form-group-s1__wrapper__content__input"
					[(ngModel)]="value"
					(ngModelChange)="onChange($event)"
					(blur)="onTouched()"
					[disabled]="isDisabled || disabled"
					[id]="id"
					[type]="type"
					[placeholder]="placeholder || label"
					[maxlength]="maxlength"
					[minlength]="minlength"
					[max]="max"
					[min]="min"
				/>
			</ng-container>

			<ng-container *ngIf="type === 'onlyNumber'">
				<input
					formInputTrim
					class="vl-form-group-s1__wrapper__content__input"
					[(ngModel)]="value"
					(ngModelChange)="onChange($event)"
					(blur)="onTouched()"
					[disabled]="isDisabled || disabled"
					[id]="id"
					type="text"
					oninput="this.value = this.value.replace(/[^0-9,.]/g, '').replace(/,/,'.').replace(/^(\d*\.\d{0,2}).*$/, '$1')"
					[placeholder]="placeholder || label"
					[maxlength]="maxlength"
					[minlength]="minlength"
					[max]="max"
					[min]="min"
				/>
			</ng-container>

			<ng-container *ngIf="type === 'range-num'">
				<div [ngClass]="['vl-box-horizontal']">
					<div [ngClass]="['vl-box-vertical']">
						<label class="vl-form-group-s1__label">Desde:</label>
						<input
							type="text"
							formInputTrim
							[(ngModel)]="multiInput[0]"
							(ngModelChange)="this.changeMultiValue(0, $event)"
							class="vl-form-group-s1__wrapper__content__input"
							maxlength="10"
							oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
						/>
					</div>

					<div [ngClass]="['vl-box-vertical']">
						<label class="vl-form-group-s1__label">Hasta:</label>
						<input
							type="text"
							formInputTrim
							[(ngModel)]="multiInput[1]"
							(ngModelChange)="this.changeMultiValue(1, $event)"
							class="vl-form-group-s1__wrapper__content__input"
							maxlength="10"
							oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
						/>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="type === 'range-date'">
				<div [ngClass]="['vl-box-horizontal']">
					<div [ngClass]="['vl-box-vertical']">
						<label class="vl-form-group-s1__label">Desde:</label>
						<div class="vl-form-group-s1__wrapper__content__inputDate">
							<input
								#initDate
								type="text"
								[matDatepicker]="pickerStart"
								[disabled]="true"
								[max]="multiInput[1] ?? maxDateFilter"
								placeholder="dd/mm/aaaa"
								(click)="pickerStart.open()"
								[(ngModel)]="multiInput[0]"
								(ngModelChange)="this.changeMultiValue(0, $event)"
							/>
							<button type="button" class="triggerPicker" (click)="pickerStart.open()">
								<ng-container [ngTemplateOutlet]="iconCalendar"></ng-container>
							</button>
							<mat-datepicker #pickerStart [disabled]="false"></mat-datepicker>
						</div>
					</div>

					<div [ngClass]="['vl-box-vertical']">
						<label class="vl-form-group-s1__label">Hasta:</label>
						<div class="vl-form-group-s1__wrapper__content__inputDate">
							<input
								#endDate
								type="text"
								[matDatepicker]="pickerEnd"
								[disabled]="true"
								[min]="multiInput[0] ?? ''"
								[max]="maxDateFilter"
								placeholder="dd/mm/aaaa"
								(click)="pickerEnd.open()"
								[(ngModel)]="multiInput[1]"
								(ngModelChange)="this.changeMultiValue(1, $event)"
							/>
							<button type="button" class="triggerPicker" (click)="pickerEnd.open()">
								<ng-container [ngTemplateOutlet]="iconCalendar"></ng-container>
							</button>
							<mat-datepicker #pickerEnd [disabled]="false"></mat-datepicker>
						</div>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="type === 'date'">
				<div class="vl-form-group-s1__wrapper__content__inputDate">
					<input
						type="text"
						[matDatepicker]="picker"
						[disabled]="true"
						placeholder="dd/mm/aaaa"
						(click)="picker.open()"
						[(ngModel)]="value"
						(ngModelChange)="onChange($event)"
						[max]="max"
						[min]="min"
					/>
					<button type="button" class="triggerPicker" (click)="picker.open()">
						<ng-container [ngTemplateOutlet]="iconCalendar"></ng-container>
					</button>
					<mat-datepicker #picker [disabled]="false"></mat-datepicker>
				</div>
			</ng-container>

			<ng-container *ngIf="type === 'textarea'">
				<textarea
					class="vl-form-group-s1__wrapper__content__textarea"
					formInputTrim
					[(ngModel)]="value"
					(ngModelChange)="onChange($event)"
					(blur)="onTouched()"
					[disabled]="isDisabled || disabled"
					[placeholder]="placeholder || label"
				></textarea>
			</ng-container>

			<ng-container *ngIf="type === 'tel'">
				<input
					formInputTrim
					class="vl-form-group-s1__wrapper__content__input"
					[(ngModel)]="value"
					(ngModelChange)="onChange($event)"
					(blur)="onTouched()"
					[disabled]="isDisabled || disabled"
					[id]="id"
					[type]="type"
					[placeholder]="placeholder || label"
					[maxlength]="maxlength"
					[minlength]="minlength"
					[max]="max"
					[min]="min"
					type="tel"
					oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
				/>
			</ng-container>

			<ng-container *ngIf="type === 'range'">
				<div class="wrapper-group-range">
					<vl-range [value]="this.value" (changeValue)="this.value = $event; onChange($event)" [rangeDisable]="rangeDisable"></vl-range>
					<span class="rangeInfo">{{ this.value }}%</span>
				</div>
			</ng-container>

			<ng-container *ngIf="type === 'select-single'">
				<vl-select-custom
					[value]="this.value"
					[placeholder]="placeholder"
					(changeSelection)="this.value = $event; onChange($event)"
					[config]="{
						type: 'text',
						options: optionsoFSelect
					}"
					[disabled]="isDisabled || disabled"
				></vl-select-custom>
			</ng-container>

			<ng-container *ngIf="type === 'select-multi'">
				<vl-select-custom
					[value]="this.value"
					(changeSelection)="this.value = $event; onChange($event)"
					[config]="{
						type: 'text',
						isMulti: true,
						options: optionsoFSelect
					}"
					[disabled]="isDisabled || disabled"
				></vl-select-custom>
			</ng-container>

			<ng-container *ngIf="type === 'select-icon'">
				<vl-select-custom
					[value]="this.value"
					(changeSelection)="this.value = $event; onChange($event)"
					[config]="{
						type: 'icon',
						options: optionsoFSelect
					}"
				></vl-select-custom>
			</ng-container>

			<ng-container *ngIf="type === 'check'">
				<button type="button" class="vl-checkbox-form" (click)="checkChangeValue()" [disabled]="disabled">
					<label class="vl-checkbox-form__wrapper">
						<input type="checkbox" [checked]="this.value" disabled />
						<span class="vl-checkbox-form__wrapper__check"></span>
					</label>
					<p class="vl-checkbox-form__text">{{ checkMsg }}</p>
				</button>
			</ng-container>

			<ng-container *ngIf="type === 'check-invert'">
				<button type="button" class="vl-checkbox-form" (click)="checkChangeValue()" [disabled]="disabled">
					<label class="vl-checkbox-form__wrapper">
						<input type="checkbox" [checked]="this.value === false" disabled />
						<span class="vl-checkbox-form__wrapper__check"></span>
					</label>
					<p class="vl-checkbox-form__text">{{ checkMsg }}</p>
				</button>
			</ng-container>

			<ng-container *ngIf="type === 'upload'">
				<input
					type="file"
					[multiple]="false"
					[accept]="accept"
					(change)="uploadFile($event)"
					class="vl-form-group-s1__wrapper__content__inputFile"
					#inputFile
				/>
				<button
					class="vl-form-group-s1__wrapper__content__boxUpload"
					type="button"
					*ngIf="!this.fileTemp?.name && !this.value"
					(click)="inputFile.click()"
				>
					<svg
						class="vl-form-group-s1__wrapper__content__boxUpload__icon"
						viewBox="0 0 12 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4.81303 8C4.69939 7.95312 4.63405 7.83825 4.65464 7.72168V3.54034H3.21877C2.98933 3.51851 2.9369 3.42109 3.07789 3.25172C3.91392 2.24371 4.75125 1.23813 5.59411 0.239058C5.86922 -0.0854316 5.99673 -0.0738884 6.28926 0.239058C7.15618 1.20609 8.02224 2.17356 8.8872 3.14156C8.93522 3.2125 8.97323 3.28935 9 3.36996C8.91762 3.4036 8.83104 3.42686 8.74229 3.43921C8.31825 3.4469 7.89418 3.43921 7.47004 3.43921H7.31175V7.72551C7.33037 7.84003 7.26101 7.95096 7.14671 7.9897H4.81302L4.81303 8Z"
							fill="currentColor"
						/>
						<path
							d="M0.00730957 8.00008H1.66333V10.5105H10.332V8.00107H11.9928C11.9928 8.03976 12 8.0773 12 8.11159V11.1867C12 11.6928 11.6532 11.9999 11.0868 11.9999H0.902462C0.456064 12.0043 0.141665 11.8032 0.0336011 11.4519C0.0105826 11.3737 -0.000718878 11.2931 3.54047e-05 11.2121V8.09395C3.54047e-05 8.06628 0.000110881 8.03769 0.00730957 8.00008Z"
							fill="currentColor"
						/>
					</svg>

					Adjuntar archivo
				</button>

				<p *ngIf="this.fileTemp?.name || this.value" class="vl-form-group-s1__wrapper__content__btnRefUpload">
					<span class="vl-form-group-s1__wrapper__content__btnRefUpload__text">{{ this.fileTemp?.name ?? this.fileName }}</span>

					<button class="vl-form-group-s1__wrapper__content__btnRefUpload__btnDelete" (click)="clearRefFile()">
						<vl-icon name="vli_delete"></vl-icon>
					</button>
				</p>
			</ng-container>

			<ng-container *ngIf="type === 'dataList'">
				<vl-datalist
					[value]="this.value"
					[customMsgNotValue]="this.placeholder"
					(onChange)="this.value = $event; onChange($event)"
					[options]="optionDatalist"
					[fnSearch]="fnSearch"
				></vl-datalist>
			</ng-container>

			<ng-container *ngIf="type === 'dataList-multi'">
				<vl-datalist
					[value]="this.value"
					[customMsgNotValue]="this.placeholder"
					(onChange)="this.value = $event; onChange($event)"
					[options]="optionDatalist"
					[fnSearch]="fnSearch"
					[isMulti]="true"
				></vl-datalist>
			</ng-container>

			<ng-container *ngIf="type === 'switch'">
				<button type="button" class="vl-switch-btn" (click)="checkChangeValue()" [disabled]="disabled">
					<p class="vl-switch-btn__text">{{ checkMsg }}</p>
					<div [ngClass]="['vl-switch', this.value ? '--active' : '']">
						<span class="vl-switch__slider"></span>
					</div>
				</button>
			</ng-container>

			<ng-container *ngIf="type === 'color'">
				<div class="vl-form-group-s1__wrapper__content__color" (click)="inputColor.click()">
					<input
						#inputColor
						class="vl-form-group-s1__wrapper__content__color__input"
						[(ngModel)]="value"
						(ngModelChange)="onChange($event)"
						(blur)="onTouched()"
						[disabled]="isDisabled || disabled"
						[id]="id"
						type="color"
						[placeholder]="placeholder || label"
					/>
					<p class="vl-form-group-s1__wrapper__content__color__text">{{ this.value || '#000000' | uppercase }}</p>
				</div>
			</ng-container>

			<ng-container *ngIf="type === 'upload-img'">
				<vl-input-upload-img
					[value]="this.value"
					[bgColorBoxImg]="bgColorBoxImg"
					(onChange)="onChange($event)"
					[maxSizeFile]="maxSizeFile"
				></vl-input-upload-img>
			</ng-container>
		</label>
		<p class="vl-form-group-s1__wrapper__length" *ngIf="showLength">
			{{ value?.length }} <span class="vl-form-group-s1__wrapper__maxLength" *ngIf="showMaxLength && maxlength"> / {{ maxlength }}</span>
		</p>
		<p class="vl-form-group-s1__wrapper__error">{{ hasErrors }}</p>
	</div>
</div>

<ng-template #iconCalendar>
	<svg viewBox="0 0 37 41" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.84082 40.666C3.04082 40.666 2.34082 40.366 1.74082 39.766C1.14082 39.166 0.84082 38.466 0.84082 37.666V6.66602C0.84082 5.86602 1.14082 5.16602 1.74082 4.56602C2.34082 3.96602 3.04082 3.66602 3.84082 3.66602H7.09082V0.666016H10.3408V3.66602H27.3408V0.666016H30.5908V3.66602H33.8408C34.6408 3.66602 35.3408 3.96602 35.9408 4.56602C36.5408 5.16602 36.8408 5.86602 36.8408 6.66602V37.666C36.8408 38.466 36.5408 39.166 35.9408 39.766C35.3408 40.366 34.6408 40.666 33.8408 40.666H3.84082ZM3.84082 37.666H33.8408V16.166H3.84082V37.666ZM3.84082 13.166H33.8408V6.66602H3.84082V13.166ZM3.84082 13.166V6.66602V13.166Z"
			fill="currentColor"
		/>
	</svg>
</ng-template>
