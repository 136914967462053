import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    fbAsyncInit: any;
  }
}

window.fbAsyncInit = window.fbAsyncInit || {};

@Injectable({
  providedIn: 'root',
})
export class FbsdkService {
  _isLoggingIn: boolean = false;
  permissions = [
    'public_profile',
    'email',
    'business_management',
    'pages_messaging',
    'pages_manage_metadata',
    'pages_show_list',
    'whatsapp_business_management',
    'whatsapp_business_messaging',
    'instagram_basic',
    'instagram_manage_messages',
  ];

  set isLoggingIn(value: boolean) {
    this._isLoggingIn = value;
  }

  get isLoggedIn(): boolean {
    return this._isLoggingIn;
  }

  constructor() { }

  init() {
    let callback = this.loginStatus;
    window.fbAsyncInit = async () => {
      FB.init({
        appId: environment.facebookAppId,
        cookie: true,
        version: 'v18.0',
      });

      FB.AppEvents.logPageView();

      const p: any = await new Promise((resolve, reject) => {
        FB.getLoginStatus((response: any) => {
          resolve(response);
        });
      });
      this.isLoggingIn = p.status === 'connected';

      // callback()
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js');
      // js.src = 'https://connect.facebook.net/en_US/sdk.js' // eslint-disable-line no-use-before-define
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }
  /**
   *
   * @returns access token for page
   */
  async loginStatus(): Promise<any> {
    const p: any = await new Promise((resolve, reject) => {
      FB.getLoginStatus((response: any) => {
        resolve(response);
      });
    });
    this.isLoggingIn = p.status === 'connected';
    return p;
  }

  /**
   *
   * @returns access token for page
   */
  async login(): Promise<any> {
    const p = new Promise((resolve, reject) => {
      FB.login(
        function (response: any) {
          console.log(response);
          if (response.authResponse) {
            console.log('Welcome!  Fetching your information.... ');
            resolve(response);
          } else {
            console.log('User cancelled login or did not fully authorize.');
            reject({
              error: 'User cancelled login or did not fully authorize.',
              code: 'unauthorized',
            });
          }
        },
        {
          scope: this.permissions.join(','),
          return_scopes: true,
        }
      );
    });
    const result: any = await p;
    this.isLoggingIn = result.status === 'connected';
    return result;
  }

  async logout(): Promise<any> {
    const p = new Promise((resolve, _) => FB.logout((response: any) => resolve(response)));
    const result = await p;
    this._isLoggingIn = false;
    return result;
  }

  getUserAccessTokens(): Promise<any> {
    return new Promise((resolve, reject) => {
      FB.api('/me/accounts', (response: any) => resolve(response.data), {
        fields: 'name,access_token',
      });
    });
  }

  // get instagram business account
  getInstagramBusinessAccount(page_id: string, access_token: string) {
    return new Promise((resolve, reject) => {
      FB.api(`/${page_id}`, (response: any) => resolve(response), {
        access_token,
        fields: 'instagram_business_account,name,access_token',
      });
    });
  }

  /**
   *@description Subscribe
   * @param page_id page id
   * @param app_id app id
   * @param access_token access token
   * @returns access token for page
   */
  subscribeToApp(page_id: string, app_id: string, access_token: string) {
    return new Promise((resolve, reject) => {
      FB.api(
        '/' + page_id + '/subscribed_apps?access_token=' + access_token,
        'POST',
        {
          app_id,
          access_token: access_token,
          subscribed_fields: 'messages,messaging_postbacks',
        },
        (response: any) => resolve(response)
      );
    });
  }

  unsubscribeToApp(page_id: string, app_id: string, access_token: string) {
    return new Promise((resolve, reject) => {
      FB.api(
        '/' + page_id + '/subscribed_apps?access_token=' + access_token,
        'delete',
        {
          app_id,
          access_token: access_token,
        },
        (response: any) => resolve(response)
      );
    });
  }

  getBusinessAccounts() {
    return new Promise((resolve, reject) => {
      FB.api('/me/businesses', (response: any) => resolve(response), {
        fields: 'name,access_token',
      });
    });
  }

  getWhatsappBusinessAccount(page_id: string) {
    return new Promise((resolve, reject) => {
      FB.api(`/${page_id}/owned_whatsapp_business_accounts`, (response: any) => resolve(response), {
        fields: 'name',
      });
    });
  }
  // get eligible pages
  getEligiblePages(page_id: string, access_token: string) {
    return new Promise((resolve, reject) => {
      FB.api(`/${page_id}/conversations`, (response: any) => resolve(response), {
        access_token,
      });
    });
  }
  // get list of system users
  getSystemUsers(business_id: string) {
    return new Promise((resolve, reject) => {
      FB.api(`/${business_id}/system_users`, (response: any) => resolve(response), {
        fields: 'name',
      });
    });
  }

  // create a system user
  createSystemUser(business_id: string, name: string) {
    return new Promise((resolve, reject) => {
      FB.api(`/${business_id}/system_users`, 'POST', { name, role: 'ADMIN' }, (response: any) => resolve(response));
    });
  }
  getInfoUser(USER_ID: any, access_token: string) {
    return new Promise((resolve, reject) => {
      FB.api(USER_ID + '?metadata=1&access_token=' + access_token, (response: any) => resolve(response), {
        fields: 'name',
      });
    });
  }
}
