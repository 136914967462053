import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '@services/backend/api.service';
import { DimensionsScreenService } from '@services/components/dimensions-screen.service';
import { TourService } from '@services/components/tour.service';
import { filter, lastValueFrom, Subject, takeUntil, tap } from 'rxjs';
import { WebSocketService } from 'src/app/services/backend/web-socket.service';
import { AuthService } from 'src/app/services/storage/auth.service';
import { LocalstorageService } from 'src/app/services/storage/localstorage.service';
import { capitalizeText } from 'src/app/utils/capitalizeText';
import { trigger, state, style, animate, transition, AnimationMetadata } from '@angular/animations';
import { environment } from '@environments/environment';

@Component({
	selector: 'v-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [
		trigger('tooltipTour', [
			state('show', style({ transform: 'translateX(0)' })),
			state('hidden', style({ transform: 'translateX(1000%)' })),
			transition('show => hidden', [animate('1s')]),
			transition('hidden => show', [animate('1s')]),
		]),
	],
})
export class HeaderComponent implements OnInit {
	@Output() toggleSideMenuEmit: EventEmitter<any> = new EventEmitter();

	public agentUrl: string = environment.agentURL;
	public conditionUrl: string = environment.easyServer + '/conditions';
	public screenWidth: any;
	public userInfo: any;
	public currentLevelUser: number;
	public nameUser: string = '';
	public roleName: string = '';
	public canShowTooltipTour: boolean = false;
	public msgTooltipTour: string = '';

	private _unsuscribeAll: Subject<any> = new Subject();

	constructor(
		private _authService: AuthService,
		private _storageService: LocalstorageService,
		private _dimensionsScreen: DimensionsScreenService,
		private _tourSrc: TourService,
		private route: Router,
		private _apiSrc: ApiService,
		private _ws: WebSocketService
	) {
		this._dimensionsScreen.change
			.pipe(
				takeUntil(this._unsuscribeAll),
				tap(({ width }) => (this.screenWidth = width))
			)
			.subscribe();
		this.userInfo = this._authService.getInfo();
		this.currentLevelUser = this.userInfo.level;
		this._tourSrc.actions
			.pipe(
				takeUntil(this._unsuscribeAll),
				filter(({ action }: any) => action === 'showTooltipTour' || action === 'hiddenTooltipTour'),
				tap((event) => this.handlerTooltipTour(event))
			)
			.subscribe();
		if (this._ws.status.getValue() === 'disconnect') this._ws.init();
	}

	ngOnInit(): void {
		this.nameUser = `${capitalizeText(this.userInfo.firstname)} ${capitalizeText(this.userInfo.lastname)}`;

		switch (this.userInfo.level) {
			case 1000:
				this.roleName = 'Superadministrador';
				break;
			case 900:
				this.roleName = 'Administrador';
				break;
			case 800:
				this.roleName = 'Supervisor';
				break;
		}

		if (this.currentLevelUser && this.currentLevelUser >= 800) {
		}
	}

	handlerTooltipTour({ action, config }: { action: string; config: any } = { action: '', config: {} }) {
		if (action !== 'showTooltipTour') return this.hiddenTooltipTour();

		const { msg } = config;

		this.msgTooltipTour = msg;
		this.canShowTooltipTour = true;
		setTimeout(() => {
			if (this.canShowTooltipTour) {
				this.hiddenTooltipTour();
			}
		}, 5000);
	}

	toggleMenuMobile(event: any) {
		this.toggleSideMenuEmit.emit(event);
	}

	hiddenTooltipTour($event?: MouseEvent) {
		if ($event) {
			$event.stopPropagation();
		}
		this.canShowTooltipTour = false;
	}

	activateTour() {
		this._tourSrc.startTour(this.route);
	}

	closeSession() {
		console.log(this._ws.status.getValue());

		if (this.userInfo) {
			this.userInfo = undefined;
			this._authService.clearAll();
			this._ws.closeSocket();
			this.route.navigate(['auth/login']);
		}
	}

	openWidgetSupport() {
		const btnTriggerWidget = document.querySelector('button.wd-trigger-wrapper');
		if (btnTriggerWidget) {
			(btnTriggerWidget as HTMLButtonElement).click();
		}
	}
}
