<div class="content-sub-menu">
  <ng-container *ngIf="screenWidth < 1025; else laptop">
    <div class="navigation-head">
      <img class="logoVideoLink" src="assets/logos/videolink.svg" />
      <div class="company" *ngIf="screenWidth > 1025">
        <img class="company__logo" [src]="company?.imgSrc || 'assets/logos/logo_alternativo.png'" />
        <p class="company__name">{{ company?.name }}</p>
      </div>
    </div>
    <div class="navigation">
      <nav>
        <div class="ul-nav">
          <mat-accordion class="app-nav-accordion">
            <mat-expansion-panel class="mat-elevation-z0" #accordeonHome
              [expanded]="isAnalytics || principalAccordionActive === 1">
              <mat-expansion-panel-header [ngClass]="['app-nav-accordion__header', isAnalytics ? 'childActive' : '']"
                (click)="isAnalytics && accordeonHome.open(); setPrincipalAccordionActive(1)">
                <mat-panel-title class="text-truncate" [ngClass]="
										isAnalytics || principalAccordionActive === 1
											? 'app-nav-accordion__header__li-nav-submenublue'
											: 'app-nav-accordion__header__li-nav-submenu'
									">
                  <div class="title-menu-nav__icon2">
                    <ng-container *ngIf="isAnalytics || principalAccordionActive === 1; then homeCircle; else home">
                    </ng-container>
                    <ng-template #homeCircle>
                      <v-icon name="circle-home"> </v-icon>
                    </ng-template>
                    <ng-template #home>
                      <v-icon name="vl-menu-home" style="margin-left: 3px; margin-top: -1px"></v-icon>
                    </ng-template>
                  </div>
                  <span class=""
                    [ngClass]="[isAnalytics || principalAccordionActive === 1 ? 'title-menu-nav__title' : 'li-nav-submenu__text2']">Inicio</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list class="submenu__accordion">
                <v-sub-menu-item *ngFor="let item of analyticsItems" [data]="{ link: item.link, icon: item.icon }"
                  (clicked)="forceCloseMenuMobile($event)">{{ item.label }}</v-sub-menu-item>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="ul-nav">
          <mat-accordion class="app-nav-accordion">
            <mat-expansion-panel class="mat-elevation-z0" #accordeonAttention
              [expanded]="isAttentionView || principalAccordionActive === 2">
              <mat-expansion-panel-header
                [ngClass]="['app-nav-accordion__header', isAttentionView ? 'childActive' : '']"
                (click)="isAttentionView && accordeonAttention.open(); setPrincipalAccordionActive(2)">
                <!-- closeAllAccordionAndForceMobile($event); -->
                <!-- 	[routerLink]="['/dashboard/attentions/list']" -->
                <mat-panel-title class="text-truncate" [ngClass]="
										isAttentionView || principalAccordionActive === 2
											? 'app-nav-accordion__header__li-nav-submenublue'
											: 'app-nav-accordion__header__li-nav-submenu'
									">
                  <div class="title-menu-nav__icon2">
                    <ng-container
                      *ngIf="isAttentionView || principalAccordionActive === 2; then attentionCircle; else attention">
                    </ng-container>
                    <ng-template #attentionCircle>
                      <v-icon name="circle-attention"> </v-icon>
                    </ng-template>
                    <ng-template #attention>
                      <v-icon name="normal-attention" style="margin-left: 6px; margin-top: -4px"></v-icon>
                    </ng-template>
                  </div>
                  <span
                    [ngClass]="[isAttentionView || principalAccordionActive === 2 ? 'title-menu-nav__title' : 'li-nav-submenu__text2']">Atenciones</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list class="submenu__accordion">
                <v-sub-menu-item *ngFor="let item of attentionsItems" [data]="{ link: item.link, icon: item.icon }"
                  (clicked)="forceCloseMenuMobile($event)">{{ item.label }}</v-sub-menu-item>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="ul-nav">
          <mat-accordion class="app-nav-accordion">
            <mat-expansion-panel class="mat-elevation-z0" #accordonChannels
              [expanded]="isChannel || principalAccordionActive === 3">
              <mat-expansion-panel-header [ngClass]="['app-nav-accordion__header', isChannel ? 'childActive' : '']"
                (click)="isChannel && accordonChannels.open(); setPrincipalAccordionActive(3)">
                <mat-panel-title class="text-truncate" [ngClass]="
										isChannel || principalAccordionActive === 3
											? 'app-nav-accordion__header__li-nav-submenublue'
											: 'app-nav-accordion__header__li-nav-submenu'
									">
                  <div class="li-nav-submenu__icon2">
                    <ng-container *ngIf="isChannel || principalAccordionActive === 3; then channelCircle; else channel">
                    </ng-container>
                    <ng-template #channelCircle>
                      <v-icon name="circle-channel"> </v-icon>
                    </ng-template>
                    <ng-template #channel>
                      <v-icon name="normal-channel" style="margin-left: 6px; margin-top: -4px"></v-icon>
                    </ng-template>
                  </div>
                  <span
                    [ngClass]="[isChannel || principalAccordionActive === 3 ? 'title-menu-nav__title' : 'li-nav-submenu__text2']">Canales</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list class="submenu__accordion">
                <mat-accordion class="app-nav-accordion">
                  <mat-expansion-panel class="mat-elevation-z0" [expanded]="accordionActive === 3 || isContactCenter">
                    <mat-expansion-panel-header
                      [ngClass]="['app-nav-accordion__header', isContactCenter ? 'childActive' : '']"
                      (click)="setAccordionActive(3)">
                      <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu">
                        <div class="li-nav-submenu__icon">
                          <v-icon name="hook"></v-icon>
                        </div>
                        <span class="li-nav-submenu__text">Contact center</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list class="submenu__accordion">
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/contact-center/personalization']"
                        routerLinkActive="isActive" (click)="forceCloseMenuMobile($event)">
                        <span class="text-truncate">Personalizar</span>
                      </a>
                      <!-- <a
												[disableRipple]="true"
												mat-list-item
												class="submenu__accordion__item"
												[routerLink]="['/dashboard/channels/contact-center/banner']"
												routerLinkActive="isActive" (click)="forceCloseMenuMobile($event)">
												<span class="text-truncate">Banner Principal</span>
											</a>
											<a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
												[routerLink]="['/dashboard/channels/contact-center/attention']"
												routerLinkActive="isActive" (click)="forceCloseMenuMobile($event)">
												<span class="text-truncate">Atenciones</span>
											</a>
											<a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
												[routerLink]="['/dashboard/channels/contact-center/forms']"
												routerLinkActive="isActive" (click)="forceCloseMenuMobile($event)">
												<span class="text-truncate">Formulario</span>
											</a> -->
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/contact-center/integration']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)">
                        <span class="text-truncate">Integración</span>
                      </a>
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/contact-center/aplications']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)">
                        <span class="text-truncate">Aplicaciones</span>
                      </a>
                    </mat-nav-list>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion class="app-nav-accordion">
                  <mat-expansion-panel class="mat-elevation-z0" [expanded]="accordionActive === 1 || isWhatsapp">
                    <mat-expansion-panel-header
                      [ngClass]="['app-nav-accordion__header', isWhatsapp ? 'childActive' : '']"
                      (click)="setAccordionActive(1)">
                      <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu">
                        <div class="li-nav-submenu__icon">
                          <v-icon name="whatsapp"></v-icon>
                        </div>
                        <span class="li-nav-submenu__text">WhatsApp</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list class="submenu__accordion">
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/whatsapp/firsts-steps']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)">
                        <span class="text-truncate">Primeros pasos</span>
                      </a>
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/whatsapp/activation']" routerLinkActive="isActive"
                        *ngIf="whatsappMembership" (click)="forceCloseMenuMobile($event)">
                        <span class="text-truncate">Activación</span>
                      </a>
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/whatsapp/personalize']" routerLinkActive="isActive"
                        *ngIf="whatsappMembership" (click)="forceCloseMenuMobile($event)">
                        <span class="text-truncate">Chatbot</span>
                      </a>
                    </mat-nav-list>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion class="app-nav-accordion">
                  <mat-expansion-panel class="mat-elevation-z0" [expanded]="accordionActive === 2 || isFacebook">
                    <mat-expansion-panel-header
                      [ngClass]="['app-nav-accordion__header', isFacebook ? 'childActive' : '']"
                      (click)="setAccordionActive(2)">
                      <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu">
                        <div class="li-nav-submenu__icon">
                          <v-icon name="facebook"></v-icon>
                        </div>
                        <span class="li-nav-submenu__text">Facebook</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list class="submenu__accordion">
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/facebook/activation']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)">
                        <span class="text-truncate">Activación</span>
                      </a>
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/facebook/personalize']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)" *ngIf="facebookMembership">
                        <span class="text-truncate">Chatbot</span>
                      </a>
                    </mat-nav-list>
                  </mat-expansion-panel>
                </mat-accordion>

                <div class="li-nav-submenu" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="isActive"
                  [routerLink]="['/dashboard/channels/widget']"
                  (click)="forceCloseMenuMobile($event); setAccordionActive(4)">
                  <div matPrefix routerLinkActive="isActive" class="li-nav-submenu__icon">
                    <v-icon name="widget"></v-icon>
                  </div>
                  <span class="li-nav-submenu__text">Widget</span>
                </div>
                <div class="li-nav-submenu" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="isActive"
                  [routerLink]="['/dashboard/channels/instagram']"
                  (click)="forceCloseMenuMobile($event); setAccordionActive(4)">
                  <div matPrefix routerLinkActive="isActive" class="li-nav-submenu__icon">
                    <v-icon name="instagram"></v-icon>
                  </div>
                  <span class="li-nav-submenu__text">Instagram</span>
                </div>
                <div class="li-nav-submenu" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="isActive"
                  [routerLink]="['/dashboard/channels/telegram']"
                  (click)="forceCloseMenuMobile($event); setAccordionActive(5)">
                  <div matPrefix routerLinkActive="isActive" class="li-nav-submenu__icon">
                    <v-icon name="v-telegram"></v-icon>
                  </div>
                  <span class="li-nav-submenu__text">Telegram</span>
                </div>
                <div class="li-nav-submenu" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="isActive"
                  [routerLink]="['/dashboard/channels/voiceIp']"
                  (click)="forceCloseMenuMobile($event); setAccordionActive(6)">
                  <div matPrefix routerLinkActive="isActive" class="li-nav-submenu__icon">
                    <v-icon name="voiceIp"></v-icon>
                  </div>
                  <span class="li-nav-submenu__text">Llamadas</span>
                </div>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="ul-nav">
          <mat-accordion class="app-nav-accordion">
            <mat-expansion-panel class="mat-elevation-z0" #accordeonConfig
              [expanded]="isConfigurationView || principalAccordionActive === 4">
              <mat-expansion-panel-header
                [ngClass]="['app-nav-accordion__header', isConfigurationView ? 'childActive' : '']"
                (click)="isConfigurationView && accordeonConfig.open(); setPrincipalAccordionActive(4)">
                <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu" [ngClass]="
										isConfigurationView || principalAccordionActive === 4
											? 'app-nav-accordion__header__li-nav-submenublue'
											: 'app-nav-accordion__header__li-nav-submenu'
									">
                  <div class="li-nav-submenu__icon2">
                    <ng-container
                      *ngIf="isConfigurationView || principalAccordionActive === 4; then configCircle; else config">
                    </ng-container>
                    <ng-template #configCircle>
                      <v-icon name="circle-settings"> </v-icon>
                    </ng-template>
                    <ng-template #config>
                      <v-icon name="normal-settings" style="margin-left: 6px; margin-top: -4px"></v-icon>
                    </ng-template>
                  </div>
                  <span
                    [ngClass]="[isConfigurationView || principalAccordionActive === 4 ? 'title-menu-nav__title' : 'li-nav-submenu__text2']">Ajustes</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list class="submenu__accordion">
                <v-sub-menu-item *ngFor="let item of ajustesItems" [data]="{ link: item.link, icon: item.icon }"
                  (clicked)="forceCloseMenuMobile($event)">{{ item.label }}</v-sub-menu-item>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <div class="ul-nav">
          <mat-accordion class="app-nav-accordion">
            <mat-expansion-panel class="mat-elevation-z0" #accordeonProfile
              [expanded]="isProfile || principalAccordionActive === 5">
              <mat-expansion-panel-header [ngClass]="['app-nav-accordion__header', isProfile ? 'childActive' : '']"
                (click)="isProfile && accordeonConfig.open(); setPrincipalAccordionActive(5)">
                <!-- closeAllAccordionAndForceMobile($event); -->
                <!-- 	[routerLink]="['/dashboard/profile']" -->
                <mat-panel-title class="text-truncate" [ngClass]="
										isProfile || principalAccordionActive === 5
											? 'app-nav-accordion__header__li-nav-submenublue'
											: 'app-nav-accordion__header__li-nav-submenu'
									">
                  <div class="li-nav-submenu__icon">
                    <img class="company__logo" [src]="company?.imgSrc || 'assets/logos/logo_alternativo.png'" />
                  </div>
                  <span
                    [ngClass]="[isProfile || principalAccordionActive === 5 ? 'title-menu-nav__title' : 'li-nav-submenu__text2']">Perfil</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-nav-list class="submenu__accordion">
                <v-sub-menu-item *ngFor="let item of profileItems" [data]="{ link: item.link, icon: item.icon }"
                  (clicked)="forceCloseMenuMobile($event)">{{ item.label }}</v-sub-menu-item>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </nav>
    </div>
  </ng-container>

  <ng-template #laptop>
    <div class="navigation">
      <nav>
        <div [ngSwitch]="currentRoute">
          <div *ngSwitchCase="'analytics'">
            <div class="ul-nav">
              <div class="title-menu-nav">
                <span class="title-menu-nav__title" [routerLink]="['/dashboard/analytics']" routerLinkActive="isActive"
                  [routerLinkActiveOptions]="{ exact: false }" (click)="forceCloseMenuMobile($event)">
                  ANALÍTICAS
                </span>
              </div>

              <ng-container *ngFor="let item of analyticsItems">
                <v-sub-menu-item [data]="{ link: item.link, icon: item.icon }" *wTP="item.permissions"
                  (clicked)="item.onclick($event)">{{
                  item.label
                  }}</v-sub-menu-item>
              </ng-container>
            </div>
          </div>

          <div *ngSwitchCase="'attentions'">
            <div class="ul-nav" id="subMenuAttentions">
              <div class="title-menu-nav">
                <span class="text-truncate title-menu-nav__title" (click)="forceCloseMenuMobile($event)">atención</span>
              </div>

              <ng-container *ngFor="let item of attentionsItems">
                <v-sub-menu-item [data]="{ link: item.link, icon: item.icon }" *wTP="item.permissions"
                  (clicked)="item.onclick($event)">{{
                  item.label
                  }}</v-sub-menu-item>
              </ng-container>
            </div>
          </div>

          <div *ngSwitchCase="'channels'">
            <div class="ul-nav">
              <div class="title-menu-nav">
                <span class="text-truncate title-menu-nav__title">canales</span>
              </div>

              <mat-accordion class="app-nav-accordion"
                *wTP="['co.cc.all', 'co.cc.personalize', 'co.cc.integration', 'co.app.all', 'co.app.list']">
                <mat-expansion-panel #contactCenterAccordeon class="mat-elevation-z0" [expanded]="isContactCenter"
                  (click)="isContactCenter && contactCenterAccordeon.open()">
                  <mat-expansion-panel-header
                    [ngClass]="['app-nav-accordion__header', isContactCenter ? 'childActive' : '']"
                    (click)="setAccordionActive(3)" [routerLink]="['/dashboard/channels/contact-center/']"
                    routerLinkActive="isActive" [routerLinkActiveOptions]="{ exact: false }">
                    <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu"
                      [routerLink]="['/dashboard/channels/contact-center/']" routerLinkActive="isActive"
                      [routerLinkActiveOptions]="{ exact: false }">
                      <div class="li-nav-submenu__icon">
                        <v-icon name="hook"></v-icon>
                      </div>
                      <span class="li-nav-submenu__text">Contact center</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-nav-list class="submenu__accordion">
                    <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                      [routerLink]="['/dashboard/channels/contact-center/personalization']" routerLinkActive="isActive"
                      (click)="forceCloseMenuMobile($event)" *wTP="['co.cc.all', 'co.cc.personalize']">
                      <span class="text-truncate">Personalizar</span>
                    </a>
                    <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                      [routerLink]="['/dashboard/channels/contact-center/integration']" routerLinkActive="isActive"
                      (click)="forceCloseMenuMobile($event)" *wTP="['co.cc.all', 'co.cc.integration']">
                      <span class="text-truncate">Integración</span>
                    </a>
                    <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                      [routerLink]="['/dashboard/channels/contact-center/aplications']" routerLinkActive="isActive"
                      (click)="forceCloseMenuMobile($event)" *wTP="['co.app.all', 'co.app.list']">
                      <span class="text-truncate">Aplicaciones</span>
                    </a>
                  </mat-nav-list>
                </mat-expansion-panel>
              </mat-accordion>

              <mat-accordion class="app-nav-accordion"
                *wTP="['co.whatsapp.all', 'co.whatsapp.activation', 'co.whatsapp.personalize']">
                <mat-expansion-panel #whatsappItem class="mat-elevation-z0" [expanded]="isWhatsapp">
                  <mat-expansion-panel-header
                    [ngClass]="['app-nav-accordion__header', isConversaciones ? 'childActive' : '']"
                    (click)="isWhatsapp && whatsappItem.open()"
                    [routerLink]="['/dashboard/channels/whatsapp/firsts-steps']" routerLinkActive="isActive"
                    [routerLinkActiveOptions]="{ exact: false }">
                    <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu"
                      [routerLink]="['/dashboard/channels/whatsapp/firsts-steps']" routerLinkActive="isActive"
                      [routerLinkActiveOptions]="{ exact: false }">
                      <div class="li-nav-submenu__icon">
                        <v-icon name="whatsapp"></v-icon>
                      </div>
                      <span class="li-nav-submenu__text">WhatsApp</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-nav-list class="submenu__accordion">
                    <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                      [routerLink]="['/dashboard/channels/whatsapp/firsts-steps']" routerLinkActive="isActive"
                      (click)="forceCloseMenuMobile($event)" *wTP="['co.whatsapp.all', 'co.whatsapp.activation']">
                      <span class="text-truncate">Primeros pasos</span>
                    </a>
                    <ng-container *ngIf="whatsappMembership">
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/whatsapp/activation']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)" *wTP="['co.whatsapp.all', 'co.whatsapp.activation']">
                        <span class="text-truncate">Activación</span>
                      </a>
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/whatsapp/personalize']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)" *wTP="['co.whatsapp.all', 'co.whatsapp.personalize']">
                        <span class="text-truncate">Chatbot</span>
                      </a>
                    </ng-container>
                  </mat-nav-list>
                </mat-expansion-panel>
              </mat-accordion>

              <mat-accordion class="app-nav-accordion"
                *wTP="['co.facebook.all', 'co.facebook.activation', 'co.facebook.personalize']">
                <mat-expansion-panel #facebookItem class="mat-elevation-z0" [expanded]="isFacebook">
                  <mat-expansion-panel-header [ngClass]="['app-nav-accordion__header', isFacebook ? 'childActive' : '']"
                    (click)="isFacebook && facebookItem.open()"
                    [routerLink]="['/dashboard/channels/facebook/activation']" routerLinkActive="isActive"
                    [routerLinkActiveOptions]="{ exact: false }">
                    <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu"
                      [routerLink]="['/dashboard/channels/facebook/activation']" routerLinkActive="isActive"
                      [routerLinkActiveOptions]="{ exact: false }">
                      <div class="li-nav-submenu__icon">
                        <v-icon name="facebook"></v-icon>
                      </div>
                      <span class="li-nav-submenu__text">Facebook</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-nav-list class="submenu__accordion">
                    <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                      [routerLink]="['/dashboard/channels/facebook/activation']" routerLinkActive="isActive"
                      (click)="forceCloseMenuMobile($event)" *wTP="['co.facebook.all', 'co.facebook.activation']">
                      <span class="text-truncate">Activación</span>
                    </a>
                    <ng-container *ngIf="facebookMembership">
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/facebook/personalize']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)" *wTP="['co.facebook.all', 'co.facebook.personalize']">
                        <span class="text-truncate">Chatbot</span>
                      </a>
                    </ng-container>
                  </mat-nav-list>
                </mat-expansion-panel>
              </mat-accordion>

              <mat-accordion class="app-nav-accordion"
                *wTP="['co.instagram.all', 'co.instagram.activation', 'co.instagram.personalize']">
                <mat-expansion-panel #instagramItem class="mat-elevation-z0" [expanded]="isInstagram">
                  <mat-expansion-panel-header
                    [ngClass]="['app-nav-accordion__header', isInstagram ? 'childActive' : '']"
                    (click)="isInstagram && instagramItem.open()"
                    [routerLink]="['/dashboard/channels/instagram/firsts-steps']" routerLinkActive="isActive"
                    [routerLinkActiveOptions]="{ exact: false }">
                    <mat-panel-title class="text-truncate app-nav-accordion__header__li-nav-submenu"
                      [routerLink]="['/dashboard/channels/instagram/firsts-steps']" routerLinkActive="isActive"
                      [routerLinkActiveOptions]="{ exact: false }">
                      <div class="li-nav-submenu__icon">
                        <v-icon name="instagram"></v-icon>
                      </div>
                      <span class="li-nav-submenu__text">Instagram</span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-nav-list class="submenu__accordion">
                    <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                      [routerLink]="['/dashboard/channels/instagram/firsts-steps']" routerLinkActive="isActive"
                      (click)="forceCloseMenuMobile($event)" *wTP="['co.instagram.all', 'co.instagram.activation']">
                      <span class="text-truncate">Primeros pasos</span>
                    </a>
                    <ng-container *ngIf="instagramMembership">
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/instagram/activation']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)" *wTP="['co.instagram.all', 'co.instagram.activation']">
                        <span class="text-truncate">Activación</span>
                      </a>
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/instagram/personalize']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)" *wTP="['co.instagram.all', 'co.instagram.personalize']">
                        <span class="text-truncate">Chatbot</span>
                      </a>
                      <a [disableRipple]="true" mat-list-item class="submenu__accordion__item"
                        [routerLink]="['/dashboard/channels/instagram/token']" routerLinkActive="isActive"
                        (click)="forceCloseMenuMobile($event)" *wTP="['co.instagram.all', 'co.instagram.personalize']">
                        <span class="text-truncate">
                          Token definitivo
                        </span>
                      </a>
                    </ng-container>
                  </mat-nav-list>
                </mat-expansion-panel>
              </mat-accordion>

              <v-sub-menu-item [data]="{ link: ['/dashboard/channels/widget'], icon: 'widget' }"
                (clicked)="closeAllAccordionAndForceMobile($event)"
                *wTP="['co.wid.all', 'co.wid.integration']">Widget</v-sub-menu-item>

              <!-- <v-sub-menu-item
								[data]="{ link: ['/dashboard/channels/telegram'], icon: 'v-telegram' }"
								(clicked)="closeAllAccordionAndForceMobile($event)"
								>Telegram</v-sub-menu-item
							>

							<v-sub-menu-item
								[data]="{ link: ['/dashboard/channels/voiceIp'], icon: 'voiceIp' }"
								(clicked)="closeAllAccordionAndForceMobile($event)"
								>Llamadas</v-sub-menu-item
							> -->
            </div>
          </div>

          <div *ngSwitchCase="'configurations'">
            <div class="ul-nav">
              <div class="title-menu-nav">
                <span class="text-truncate title-menu-nav__title" [routerLink]="['/dashboard/configurations']"
                  routerLinkActive="isActive" [routerLinkActiveOptions]="{ exact: false }"
                  (click)="forceCloseMenuMobile($event)">configuraciones</span>
              </div>

              <ng-container *ngFor="let item of ajustesItems">
                <v-sub-menu-item [data]="{ link: item.link, icon: item.icon }" (clicked)="item.onclick($event)"
                  *wTP="item.permissions">{{
                  item.label
                  }}</v-sub-menu-item>
              </ng-container>
            </div>
          </div>

          <div *ngSwitchCase="'profile'">
            <div class="ul-nav">
              <div class="title-menu-nav">
                <span class="text-truncate title-menu-nav__title" [routerLink]="['/dashboard/profile']"
                  routerLinkActive="isActive" [routerLinkActiveOptions]="{ exact: false }"
                  (click)="forceCloseMenuMobile($event)">Perfil</span>
              </div>

              <ng-container *ngFor="let item of profileItems">
                <v-sub-menu-item [data]="{ link: item.link, icon: item.icon }" (clicked)="item.onclick($event)"
                  *wTP="item.permissions">{{
                  item.label
                  }}</v-sub-menu-item>
              </ng-container>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </ng-template>
</div>